<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>
                Administration des médias
            </h1>
            <b-button
                size="sm"
                variant="outline-kalkin-2"
                @click="goCreateCollection"
            >
                <b-icon-plus-circle />
                Nouvelle collection
            </b-button>
        </div>

        <div class="k-page-body text-left">
            <TableCollections perPage="12" />
        </div>
    </div>
</template>

<script>
import TableCollections from "@/components/misc/admin-medias/TableCollections";
export default {
    components: {
        TableCollections,
    },

    methods: {
        goCreateCollection() {
            this.$router.push({
                name: "CreateMediaCollection",
            });
        },
    },
};
</script>
