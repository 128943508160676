<template>
    <b-badge
        class="pointer"
        :variant="tag.style"
        :key="tag.key"
        :title="tag.help"
        v-b-tooltip.hover.bottom.v-secondary
        @click="onClick(tag.key)"
    >
        {{ tag.label }}
    </b-badge>
</template>

<script>
const TAGS = [
    {
        key: "public/",
        label: "publique",
        style: "kalkin-2",
        help:
            "Collection publique, la liste de son contenu est accessible à tous via l'API",
    },
    {
        key: "project/",
        label: "projet",
        style: "kalkin-1",
        help:
            "Collection privée liée à un projet, la liste de son contenu est accessible uniquement par l'intermédiaire de l'API Core et l'authentification projet",
    },
    {
        key: "kbsit/",
        label: "sit",
        style: "kalkin-1",
        help: "Collection privée liée à un projet SIT interne",
    },
    {
        key: "private/",
        label: "privée",
        style: "danger",
        help: "Collection privée, accessible uniquement par Kalkin",
    },
    {
        key: "internal/",
        label: "interne",
        style: "danger",
        help:
            "Collection privée et interne, contient notamment les médias générés par le serveur. Accessible à titre informatif",
    },
    {
        key: "/pin_bank",
        label: "pins",
        style: "secondary",
        help: "Banque de pins",
    },
    {
        key: "/icon_bank",
        label: "icônes",
        style: "secondary",
        help: "Banque d'icônes",
    },
    {
        key: "/media_bank",
        label: "médias",
        style: "secondary",
        help: "Banque de médias",
    },
    {
        key: "/project",
        label: "généré",
        style: "secondary",
        help: "Pins générées pour un projet",
    },
    {
        key: "/cache",
        label: "cache",
        style: "secondary",
        help: "Cache interne",
    },
];

const DEFAULT_TAG = {
    key: "unknown",
    label: "inconnu",
    style: "dark",
};

export default {
    props: {
        tagKey: String,
    },

    KEYS: TAGS.map(t => t.key),

    data: function() {
        return {
            tags: TAGS,
        };
    },

    computed: {
        tag() {
            const tag = TAGS.find(t => t.key === this.tagKey);
            return tag || { ...DEFAULT_TAG, help: this.tagKey };
        },
    },

    methods: {
        onClick(payload) {
            this.$emit("click", payload);
        },
    },
};
</script>

<style></style>
