<template>
    <div class="k-flex-col">
        <div class="k-flex-row">
            <div class="flex-grow-1 mr-4">
                <b-input-group>
                    <template #prepend>
                        <b-input-group-text>
                            <b-icon-search />
                        </b-input-group-text>
                    </template>
                    <b-form-input
                        placeholder="Rechercher une collection"
                        v-model="filter"
                        debounce="500"
                    />
                </b-input-group>
            </div>
            <div class="d-flex flex-column">
                <small class="text-kalkin-1 text-center w-100">
                    Collections par page
                </small>
                <b-form-group>
                    <b-form-radio-group
                        v-model="perPage"
                        size="sm"
                        button-variant="outline-kalkin-1"
                        buttons
                    >
                        <b-form-radio :value="5">5</b-form-radio>
                        <b-form-radio :value="10">10</b-form-radio>
                        <b-form-radio :value="20">20</b-form-radio>
                        <b-form-radio :value="50">50</b-form-radio>
                        <b-form-radio :value="100">100</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
        </div>
        <div class="k-flex-row">
            <div class="k-flex-row">
                Par type :
                <template v-for="tag in tags">
                    <CollectionTag
                        :key="tag"
                        :tag-key="tag"
                        @click="filter = $event"
                    />
                </template>
            </div>
        </div>
        <b-table
            class="text-break mt-2 text-center"
            :items="dataProvider"
            :fields="fields"
            :current-page="currentPage"
            :filter="filter"
            :per-page="perPage"
            sort-by="createdAt"
            show-empty
            empty-text="Aucune collection"
            empty-filtered-text="Aucune collection trouvée"
            stacked="md"
            hover
            tbody-tr-class="pointer"
            @row-clicked="goCollection"
            striped
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
            <template #cell(createdAt)="data">
                {{ data.item.createdAt | dateFromISO(true) }}
            </template>
            <template #cell(name)="data">
                {{ data.item.name }} <small>#{{ data.item.id }}</small>
            </template>

            <template #cell(type)="data">
                <div class="d-flex align-items-center">
                    <CollectionTag
                        :tag-key="data.item.type.split('/')[0] + '/'"
                    />
                    <b-icon-caret-right variant="kalkin-1" />
                    <CollectionTag
                        :tag-key="'/' + data.item.type.split('/')[1]"
                    />
                </div>
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
import CollectionTag from "./CollectionTag.vue";

export default {
    components: {
        CollectionTag,
    },

    data: function() {
        return {
            tags: CollectionTag.KEYS,
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            fields: [
                {
                    key: "type",
                    label: "Type",
                    tdClass: "text-left",
                    sortable: true,
                },
                {
                    key: "name",
                    label: "Nom de la collection",
                    sortable: true,
                    tdClass: "text-kalkin-1 font-weight-bold text-left",
                },

                {
                    key: "description",
                    label: "Description",
                    tdClass: "text-left",
                },

                {
                    key: "mediaCount",
                    label: "Médias",
                    sortable: true,
                },
                {
                    key: "createdAt",
                    label: "Création",
                    sortable: true,
                },
            ],
            filter: "",
        };
    },

    watch: {
        async filter() {
            await this.fetchTotalRows();
        },
    },

    methods: {
        async fetchTotalRows() {
            this.totalRows = await this.$store.dispatch(
                "media/countFSPCollections",
                {
                    filter: `name substring ${this.filter} or description substring ${this.filter} or type substring ${this.filter} or id eq ${this.filter}`,
                },
            );
        },

        async dataProvider(ctx) {
            const collections = await this.$store.dispatch(
                "media/getFSPCollections",
                {
                    filter: `name substring ${this.filter} or description substring ${this.filter} or type substring ${this.filter} or id eq ${this.filter}`,
                    sortby: (ctx.sortDesc ? "-" : "") + ctx.sortBy,
                    limit: this.perPage,
                    offset: this.perPage * (this.currentPage - 1),
                },
            );
            return collections;
        },

        goCollection(item) {
            this.$router.push({
                name: "MediaCollection",
                params: { collectionId: item.id },
            });
        },
    },

    async beforeMount() {
        await this.fetchTotalRows();
    },
};
</script>
